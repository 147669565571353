import { isEmpty, isNil } from "lodash";
import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./DealEvaluation.module.scss";
import { DEAL_EVALUATION_INITIAL_STATE, updatedByDealEvaluation } from "../../../../../../../../../../../../constants/commonConstants";
import ReactTooltip from "react-tooltip";
import { UwRiskFlagTooltip } from "./UwRiskFlag/UwRiskFlagTooltip";
import {getAmountInCrorLacs, mixPanelTrack, showNotification} from "../../../../../../../../../../../../util/utility";
import {useSelector} from "react-redux";
import {getInvesteesOrganization, getRrfToggleDetails} from "../../../../../../../../../../AdminReducers";
import recurRating from "../../../../../../../../../../../../constants/recur_rating.json";
import styled from "styled-components";
import SaveSVG from "../../../../../../../../../../../../components/SVGs/SaveSVG";
import { GENERIC_ERROR, IDENTIFIER_TYPE, SHOW_NOTIFICATION } from "../../../../../../../../../../../../constants/enums";
import callApi from "../../../../../../../../../../../../util/apiCaller";
import { getEmail, getUserId } from "../../../../../../../../../../../App/AppReducer";
import { EXPIRED } from "../../../../../DocumentVault/consts/consts";

const { DealBox, uwSignal, labelTypes, boolTypeLabelWidth, checkBoxInput, lineClass, generalInput, nonRatedInput, width50, headingwrapper, dealevaluation, capitalRequested, dealEvaluationtooltip, editBtn } = styles;

const evalPoorAvgGoodTypeLabels = [
    { labelName: "Promoter CIBIL Score", evalType: "promoterCibilScore", updatedBy: "promoterCibilScoreUpdatedBy" },
    { labelName: "Company CIBIL Score", evalType: "companyCibilScore", updatedBy: "companyCibilScoreUpdatedBy" },
  { labelName:"Net-Worth", evalType: "netWorth", updatedBy: "netWorthUpdatedBy" },
];

const evalHighAvgLowTypeLabels = [
  { labelName: "Receivables", evalType: "receivables", updatedBy: "receivablesUpdatedBy" },
  { labelName: "Related Party Transactions", evalType: "relatedPartyTransactions", updatedBy: "relatedPartyTransactionsUpdatedBy" },
  { labelName: "Customer Concentration", evalType: "customerConcentration", updatedBy: "customerConcentrationUpdatedBy" },
  { labelName: "Cash Transactions", evalType: "cashTransactions", updatedBy: "cashTransactionsUpdatedBy"},
];

const evalBoolTypeLabels = [
    { labelName: "Social Presence", evalType: "socialPresence", updatedBy: "socialPresenceUpdatedBy" },
    { labelName: "Proprietary Firm", evalType: "proprietorshipFirm", updatedBy: "proprietorshipFirmUpdatedBy" },
    { labelName: "ICP Sector", evalType: "icpSector", updatedBy: "icpSectorUpdatedBy" },
    { labelName: "Legal Compliance", evalType: "legalNonCompliance", updatedBy: "legalNonComplianceUpdatedBy" },
];

function getUpdatedByStyles(updatedBy, disabled=true){
  if(updatedBy === updatedByDealEvaluation.SYSTEM && !!disabled){
    return {margin:"3px", color: "#E24CCD"}
  }else{
    return {margin:"3px"}
  }
}

function getUpdatedByStylesUwSignal(updatedBy, disabled=false){
  if(updatedBy === updatedByDealEvaluation.SYSTEM && !!disabled){
    return {margin:"3px", fontSize:"12px", color: "#E24CCD"}
  }else{
    return {margin:"3px", fontSize:"12px"}
  }
}

function getUpdatedByStylesInputVal(updatedBy){
  if(updatedBy === updatedByDealEvaluation.SYSTEM){
    return {margin:"3px", fontSize:"12px", color: "#E24CCD"}
  }else{
    return {margin:"3px", fontSize:"12px"}
  }
}

export const uw_signal_types = {
  REJECT : "reject",
  DIFFICULT: "difficult",
  SPARK: "spark",
  GOOD: "good",
  FANTASTIC: "fantastic",
};

const EditBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 4px;
    background-color: #4C8DFF;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 600;
    border: none;
    padding: 8px 14px;
    height: 28px;
    width: 70px;
    margin: 12px;
`;

function DealEvaluation({identifierId, orgId, status, note1, monthlyData, setRrfTermToggle, moderateRow, addOnId, isExpired, archived, applicationStatus}) {

  const rrfToggleInfo = useSelector(getRrfToggleDetails);
  const [dealReportState, setDealReportState] = useState({});
  const [isSaveClickedEvaluationMetrix, setIsSaveClickedEvaluationMetrix] = useState(false);
  const [isModeratingEvaluationMetrix, setIsModeratingEvaluationMetrix] = useState(false);
  const uw_signal_arr = [uw_signal_types.GOOD, uw_signal_types.FANTASTIC, uw_signal_types.SPARK, uw_signal_types.DIFFICULT]

  const userEmail = useSelector((state) => getEmail(state));
  const userId = useSelector((state) => getUserId(state));

  const NEEDINFO = "Need Information";
  const WAITLISTED = "Waitlisted";
  const APPROVED = "Approved";
  const APPROVED_RUNAWAY = "Approved-Runway<3M";

  useEffect(() => {
    if(moderateRow){
      fetchDealReportData(addOnId, orgId);
    }
  }, [moderateRow]);

  const investeeOrganization = useSelector(getInvesteesOrganization);
  useEffect(() => {
    fetchDealReportData(identifierId, orgId);    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[identifierId, orgId]);

  useEffect(() => {
    if (rrfToggleInfo && rrfToggleInfo !== '' && rrfToggleInfo?.updatedBy && (isNil(moderateRow) || rrfToggleInfo?.updatedBy !== 'system')) {
      setRrfTermToggle(rrfToggleInfo?.isOn);
    } else {
      setRrfTermToggle(uw_signal_arr.includes(dealReportState?.uwSignal));
    }
  }, [rrfToggleInfo, moderateRow, dealReportState?.uwSignal]);

  useEffect(() => {
    if (!isEmpty(dealReportState)) {
      setDealReportState(dealReportState);
    }
  }, [dealReportState]);

  const rating_types = {
    POOR: "poor",
    AVERAGE: "average",
    GOOD: "good",
    HIGH: "high",
    LOW: "low",
  };

  const bool_types = {
    YES: "yes",
    NO: "no",
  }

  const handleSignalChange = (type, initialSignal) => {
    setDealReportState({ ...dealReportState, uwSignal: type === initialSignal ? null : type, uwSignalUpdatedBy : updatedByDealEvaluation.ADMIN });
  };

  const trackDealEvaluationMixpanel = (action) => {
    const trackCategoryName = action === 'edit' ? "Edit Deal Evaluation Metrics Clicked" : "Save Deal Evaluation Metrics Clicked";
    mixPanelTrack({
      id: userId,
      trackUserObj: {
        "Org ID": orgId,
        "User Email": userEmail
      },
      trackCategoryName,
    });
  }

  const disableEdit = () => {
    if (applicationStatus === EXPIRED || applicationStatus === WAITLISTED) return true;
    return false;
  };

  const disableSave = () => {
    if (applicationStatus === EXPIRED || applicationStatus == WAITLISTED) return true;
    return false;
}

  const isEditButtonDisabled = () => (isExpired);

  const onSaveDealEvaluation = async () => {
    try {
      setIsSaveClickedEvaluationMetrix(true);
        const identifierType = IDENTIFIER_TYPE.ONBOARDING;
        setDealReportState({...dealReportState, status, identifierId, identifierType, orgId});
        const changedDealReport = {...dealReportState, status, identifierId, identifierType, orgId};

        const mandatoryFields = [
          { field: 'runway', message: 'Runway is mandatory' },
          { field: 'debtPercent', message: 'Debt(%) is mandatory' },
          { field: 'ebitdaPercent', message: 'EBITDA(%) is mandatory' }
        ];

        if ([APPROVED, WAITLISTED, APPROVED_RUNAWAY].includes(status)) {
          for (const { field, message } of mandatoryFields) {
            if (changedDealReport[field] == null) {
          showNotification(SHOW_NOTIFICATION.ERROR, message);
          return;
            }
          }
        }

      const obj = {
        status,
        comment: note1
      }

      if (!isEmpty(changedDealReport)) {
        obj.dealReport = {
            ...changedDealReport,
            runway: Number(changedDealReport.runway),
            arr: Number(changedDealReport.arr),
            debtPercent: Number(changedDealReport.debtPercent),
            growthPercent: Number(changedDealReport.growthPercent),
            ebitdaPercent: Number(changedDealReport.ebitdaPercent),
        };
        const res = await callApi(`/deal-report/data-update?requestId=${Date.now()}`, "post", {...obj.dealReport}, null, false, false, false, false, false, false, true)
        if (res?.responseData?.responseCode === 20) {
            delete res.responseData;
            setDealReportState(res);
            fetchDealReportData(identifierId, orgId);
            setIsModeratingEvaluationMetrix(false);
            showNotification(SHOW_NOTIFICATION.SUCCESS, "Evaluation Metrics successfully updated");
        } else {
            setIsSaveClickedEvaluationMetrix(false);
            showNotification(SHOW_NOTIFICATION.ERROR, res?.reponseData?.responseMessage ?? GENERIC_ERROR);
        }
      }
    } catch (error) {
      showNotification(SHOW_NOTIFICATION.ERROR, GENERIC_ERROR);
    } finally {
      setIsSaveClickedEvaluationMetrix(false);
    }
  };

const fetchDealReportData = async (identifierId, orgId) => {
  try {
    const res = await callApi(
      `deal-report/data-get?identifierId=${identifierId}&orgId=${orgId}&requestId=${Date.now()}`,
      "get",
      null,
      null,
      false,
      false,
      false,
      false,
      false,
      false,
      true
    );
    if (res?.responseData?.responseCode === 20) {
      const { responseData, ...dealReportData } = res;
      setDealReportState(dealReportData);
    } else {
      setDealReportState(DEAL_EVALUATION_INITIAL_STATE);
    }
  } catch (error) {
    showNotification(SHOW_NOTIFICATION.ERROR, 'Failed to fetch deal report data');
    setDealReportState(DEAL_EVALUATION_INITIAL_STATE);
  }
};

  const handleRatingChange = (evalType, rating, evalTypeUpdatedBy) => {
    setDealReportState({ ...dealReportState, [evalType]: dealReportState[evalType] === rating ? null : rating,  [evalTypeUpdatedBy] : updatedByDealEvaluation.ADMIN });
  };

  const [capitalRequired, setCapitalRequired] = useState({});
  useEffect(() => {
    if (!isEmpty(monthlyData)) {
      monthlyData.forEach((data) => {
        if (data._id === identifierId) {
          if (data.hasOwnProperty('capital_required'))
            setCapitalRequired(data.capital_required);
          else if(data.hasOwnProperty('add_on_amount') && data.hasOwnProperty('add_on_time_period')){
            setCapitalRequired({
              amount: data.add_on_amount,
              date_by: data.add_on_time_period
            });
          }
        }
      })
    }
  }, [monthlyData]);

  const renderButtons = () => {
    return (
      <>
      <div className={lineClass}></div>
      <div className={editBtn} >
        {isModeratingEvaluationMetrix ? (
          <div style={{width: '15rem', display: "flex", alignItems: 'center', gap: '0.5rem'}}>
            <button
              className="mr-4 btn6 btnht1 d-flex align-items-center justify-content-center"
              onClick={() => {
                setIsModeratingEvaluationMetrix(false);
              }}
            >
              <img
                src="../../../../../assets/icon-clear.svg"
                alt="cross"
                className="mr-2"
                style={{width: '11px'}}
              />
              <span>Cancel</span>
            </button>
            <button
              className="save-btn btn5 btnht1 d-flex align-items-center justify-content-center"
              style={moderateRow ? isSaveClickedEvaluationMetrix ? {cursor: 'not-allowed'} : null : (disableSave() ? {cursor: 'not-allowed'} : null)}
              onClick={() => {
                onSaveDealEvaluation()
                trackDealEvaluationMixpanel('save');
              }}
              disabled={moderateRow ? isSaveClickedEvaluationMetrix : (disableSave() || isSaveClickedEvaluationMetrix)}
            >
              <SaveSVG/>
              <span>Save</span>
            </button>
          </div>
        ) : (
          <EditBtn disabled={moderateRow ? isEditButtonDisabled() : disableEdit()} onClick={() => {trackDealEvaluationMixpanel('edit'); setIsModeratingEvaluationMetrix(true)}}>
            <img className="mr-1" src={"https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/white_editIcon-outline.svg"} alt="Edit"/>{' '}
            <span>Edit</span>
          </EditBtn>
        )}
      </div>
      </>
    );
  }

  const EvaluationPoorAvgGoodTypeRating = ({ labelName, evalType, updatedBy }) => {
    return (
      <div className="d-flex justify-content-between">
        <span className={"fs12 " + width50}>{labelName}</span>
        <div className={labelTypes}>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="poor"
              name="poor"
              checked={dealReportState?.[evalType] === rating_types.POOR}
              onChange={() => handleRatingChange(evalType, rating_types.POOR, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="poor" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.POOR)}>
              Poor
            </label>
          </div>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="average"
              name="average"
              checked={dealReportState?.[evalType] === rating_types.AVERAGE}
              onChange={() => handleRatingChange(evalType, rating_types.AVERAGE, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="average" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.AVERAGE)}>
              Average
            </label>
          </div>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="good"
              name="good"
              checked={dealReportState?.[evalType] === rating_types.GOOD}
              onChange={() => handleRatingChange(evalType, rating_types.GOOD, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="good" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.GOOD)}>
              Good
            </label>
          </div>
        </div>
      </div>
    );
  };

  const EvaluationHighAvgLowTypeRating = ({ labelName, evalType, updatedBy }) => {
    return (
      <div className="d-flex justify-content-between">
        <span className="fs12">{labelName}</span>
        <div className={labelTypes}>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="high"
              name="high"
              checked={dealReportState?.[evalType] === rating_types.HIGH}
              onChange={() => handleRatingChange(evalType, rating_types.HIGH, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="high" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.HIGH)}>
              High
            </label>
          </div>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="average"
              name="average"
              checked={dealReportState?.[evalType] === rating_types.AVERAGE}
              onChange={() => handleRatingChange(evalType, rating_types.AVERAGE, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="average" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.AVERAGE)}>
              Average
            </label>
          </div>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="low"
              name="low"
              checked={dealReportState?.[evalType] === rating_types.LOW}
              onChange={() => handleRatingChange(evalType, rating_types.LOW, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="low" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === rating_types.LOW)}>
              Low
            </label>
          </div>
        </div>
      </div>
    );
  };

  const EvalBoolTypeRating = ({ labelName, evalType, updatedBy }) => {
    return (
      <div className="d-flex justify-content-between">
        <span className="fs12">{labelName}</span>
        <div className={`${boolTypeLabelWidth}`}>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="no"
              name="no"
              checked={dealReportState?.[evalType] === bool_types.NO}
              onChange={() => handleRatingChange(evalType, bool_types.NO, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="no" className="ml-1" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === bool_types.NO)}>
              No
            </label>
          </div>
          <div>
            <input
              className={checkBoxInput}
              type="checkbox"
              id="yes"
              name="yes"
              checked={dealReportState?.[evalType] === bool_types.YES}
              onChange={() => handleRatingChange(evalType, bool_types.YES, updatedBy)}
              disabled={!isModeratingEvaluationMetrix}
            />
            <label for="yes" className="ml-1" style={getUpdatedByStyles(dealReportState?.[updatedBy], dealReportState?.[evalType] === bool_types.YES)}>
              Yes
            </label>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
    <div className={DealBox}>
      <div className={headingwrapper}>
        <img src="https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/info-icon-gree.svg" alt="" data-tip=''
             data-for={'systemUwFlag'} style={{marginRight:'0.5rem'}}/>
        <p className={dealevaluation}>Deal Evaluation</p>
        {!isEmpty(capitalRequired)&&<p className={capitalRequested}>Applied For <span>₹{getAmountInCrorLacs(capitalRequired.amount)}</span> & required for <span>{capitalRequired.date_by}</span></p>}
      </div>

      <ReactTooltip
          id={'systemUwFlag'}
          type="light"
          place="bottom"
          className={dealEvaluationtooltip}
      >
        <UwRiskFlagTooltip
        uwRiskTags={dealReportState?.systemData}
        />
      </ReactTooltip>
      
      <div className={uwSignal}>
        <span className="fs12">UW Signal</span>
        <div>
          <input
            type="checkbox"
            id="reject"
            name="reject"
            checked={dealReportState?.uwSignal === uw_signal_types.REJECT}
            onChange={() => handleSignalChange(uw_signal_types.REJECT, dealReportState?.uwSignal)}
            disabled={!isModeratingEvaluationMetrix}
            className={checkBoxInput}
          />
          <label for="difficult" style={getUpdatedByStylesUwSignal(dealReportState?.uwSignalUpdatedBy, dealReportState?.uwSignal === uw_signal_types.REJECT)}>
            Reject
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="difficult"
            name="difficult"
            checked={dealReportState?.uwSignal === uw_signal_types.DIFFICULT}
            onChange={() => handleSignalChange(uw_signal_types.DIFFICULT, dealReportState?.uwSignal)}
            disabled={!isModeratingEvaluationMetrix}
            className={checkBoxInput}
          />
          <label for="difficult" style={getUpdatedByStylesUwSignal(dealReportState?.uwSignalUpdatedBy, dealReportState?.uwSignal === uw_signal_types.DIFFICULT)}>
            Difficult
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="spark"
            name="spark"
            checked={dealReportState?.uwSignal === uw_signal_types.SPARK}
            onChange={() => handleSignalChange(uw_signal_types.SPARK, dealReportState?.uwSignal)}
            disabled={!isModeratingEvaluationMetrix}
            className={checkBoxInput}
          />
          <label for="spark" style={getUpdatedByStylesUwSignal(dealReportState?.uwSignalUpdatedBy, dealReportState?.uwSignal === uw_signal_types.SPARK)}>
            Spark
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="good"
            name="good"
            checked={dealReportState?.uwSignal === uw_signal_types.GOOD}
            onChange={() => handleSignalChange(uw_signal_types.GOOD, dealReportState?.uwSignal)}
            disabled={!isModeratingEvaluationMetrix}
            className={checkBoxInput}
          />
          <label for="good" style={getUpdatedByStylesUwSignal(dealReportState?.uwSignalUpdatedBy, dealReportState?.uwSignal === uw_signal_types.GOOD)}>
            Good
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            id="fantastic"
            name="fantastic"
            checked={dealReportState?.uwSignal === uw_signal_types.FANTASTIC}
            onChange={() => handleSignalChange(uw_signal_types.FANTASTIC, dealReportState?.uwSignal)}
            disabled={!isModeratingEvaluationMetrix}
            className={checkBoxInput}
          />
          <label for="fantastic" style={getUpdatedByStylesUwSignal(dealReportState?.uwSignalUpdatedBy, dealReportState?.uwSignal === uw_signal_types.FANTASTIC)}>
            Fantastic
          </label>
        </div>
      </div>
      <div className="mt-2">
        <div className="dflex1 br4">
          <div className="w-50">Recur Rating</div>
          <select
              style={{height:'1.75rem',border:'0.5px solid #C8C8C8',borderRadius:'0.1875rem'}}
              className='w-50'
              value={dealReportState?.recurRating??investeeOrganization?.recur_rating}
              onChange={e => handleRatingChange("recurRating", e.target.value,'recurRatingUpdatedBy')}
          >
            <option value="" disabled>Select type</option>
            {recurRating.map((item, index) => (
                <option key={index} value={item}>{item}</option>
            ))}
          </select>
        </div>
        {
          evalPoorAvgGoodTypeLabels.map((label) => {
            return <EvaluationPoorAvgGoodTypeRating labelName={label.labelName} evalType={label.evalType}
                                                    updatedBy={label.updatedBy}/>
          })
        }
        {
          evalHighAvgLowTypeLabels.map((label) => {
            return <EvaluationHighAvgLowTypeRating labelName={label.labelName} evalType={label.evalType}
                                                   updatedBy={label.updatedBy}/>
          })
        }
        {
          evalBoolTypeLabels.map((label) => {
            return <EvalBoolTypeRating labelName={label.labelName} evalType={label.evalType}
                                       updatedBy={label.updatedBy}/>
          })
        }
      </div>

      <div className={lineClass}></div>

      <div className={nonRatedInput}>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState?.arrUpdatedBy)}>ARR(INR cr)</span>
          <span className={width50}>
              <input
                  className={generalInput}
                  type="number"
                  id="arr"
                  name="arr"
                  value={dealReportState?.arr ?? ""}
                  onChange={(e) => handleRatingChange("arr", e.target.value, "arrUpdatedBy")}
                  disabled={!isModeratingEvaluationMetrix}
              />
            </span>
        </div>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState.growthPercentUpdatedBy)}>Growth(%)</span>
          <span className={width50}>
            <input
              className={generalInput}
              type="number"
              id="growth"
              name="growth"
              value={dealReportState?.growthPercent ?? ""}
              onChange={(e) => handleRatingChange("growthPercent", e.target.value, "growthPercentUpdatedBy")}
              disabled={!isModeratingEvaluationMetrix}
            />
          </span>
        </div>
      </div>
      <div className={nonRatedInput}>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState.debtPercentUpdatedBy)}>Debt(%)</span>
          <span className={width50}>
            <input
              className={generalInput}
              type="number"
              id="debt"
              name="debt"
              value={dealReportState?.debtPercent ?? ""}
              onChange={(e) => handleRatingChange("debtPercent", e.target.value, "debtPercentUpdatedBy")}
              disabled={!isModeratingEvaluationMetrix}
            />
          </span>
        </div>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState.runwayUpdatedBy)}>Runway(m)</span>
            <span className={width50}>
              <input
                className={generalInput}
                type="number"
                id="runway"
                name="runway"
                value={dealReportState?.runway ?? ""}
                onChange={(e) => handleRatingChange("runway", e.target.value, "runwayUpdatedBy")}
                disabled={!isModeratingEvaluationMetrix}
              />
            </span>
        </div>
      </div>
      <div className={nonRatedInput}>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState.debtPercentUpdatedBy)}>EBITDA(%)</span>
          <span className={width50}>
            <input
              className={generalInput}
              type="number"
              id="ebitda"
              name="ebitda"
              value={dealReportState?.ebitdaPercent ?? ""}
              onChange={(e) => handleRatingChange("ebitdaPercent", e.target.value, "ebitdaPercentUpdatedBy")}
              disabled={!isModeratingEvaluationMetrix}
            />
          </span>
        </div>
        <div className={width50}>
          <span className={width50} style={getUpdatedByStylesInputVal(dealReportState.runwayUpdatedBy)}></span>
            <span className={width50}>
            </span>
        </div>
      </div>
      { archived ? (!archived && renderButtons()) : renderButtons()}
    </div>
    </>
  );
}

export default DealEvaluation;
